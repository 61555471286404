import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Element, scroller } from "react-scroll";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Accordion = ({
  opened,
  header,
  body,
  maxW,
  author,
  ident,
  toggleConcepts,
  concert,
  saveURL,
  toggleAutotheory,
  autotheory,
  toggleInterviews,
  interview,
  hasResults,
}) => {
  const [expanded, setExpanded] = useState(opened ? true : false);
  const { t } = useTranslation();

  useEffect(() => {
    setExpanded(hasResults !== true ? opened : true);
  }, [hasResults]);

  return (
    <Element className="accordion" style={{ padding: ident && "0 20px" }} name={header}>
      <motion.header initial={false}>
        {author ? (
          <h2 className={maxW && "max-w max-w--auto"} style={{ marginTop: "5px" }}>
            <span onMouseDown={() => setExpanded(!expanded)} role="button" tabIndex={0}>
              {header}
              <span className="brackets">[{expanded ? "-" : "+"}]</span>
            </span>
          </h2>
        ) : (
          <h1 className={maxW && "max-w max-w--auto"}>
            <span
              role="button"
              tabIndex={0}
              onMouseDown={() => {
                setExpanded(!expanded);
                if (saveURL) {
                  concert && toggleConcepts(header);
                  autotheory && toggleAutotheory(header);
                  interview && toggleInterviews(header);
                }
              }}
            >
              {header}
              <span className="brackets">[{expanded ? "-" : "+"}]</span>
            </span>
          </h1>
        )}
      </motion.header>

      <AnimatePresence initial={false}>
        {expanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto", overflow: "unset" },
              collapsed: { opacity: 0, height: 0, overflow: "hidden" },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <motion.div
              transition={{ duration: 0.5 }}
              className={author ? "body-wrapper author-wrapper" : "body-wrapper"}
            >
              {body}
              {maxW && (
                <div
                  className="back-to-top"
                  onClick={() => {
                    scroller.scrollTo(header, {
                      duration: 1500,
                      smooth: true,
                      offset: -200,
                    });
                  }}
                >
                  <h2>
                    <span className="back-to-top__hide">{t("back-to-top")}</span>[
                    <span className="arrow">↑</span>]
                  </h2>
                </div>
              )}
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </Element>
  );
};

export default Accordion;
