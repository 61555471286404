//import dependencies
import * as React from "react";
import { useState } from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { NetlifyForm, Honeypot } from "react-netlify-forms";
import { useQueryParam, DelimitedArrayParam } from "use-query-params";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Link as RSLink } from "react-scroll";

//import components
import Seo from "../components/Seo";

//import styles
import "normalize.css";
import "../scss/index.scss";
import Accordion from "../components/Accordion";

const IndexPage = ({ data }) => {
  // ————————————————————————————————————
  // URL queries
  // ————————————————————————————————————

  const [concepts, setConcepts] = useQueryParam("concepts", DelimitedArrayParam);
  const [autotheory, setAutotheory] = useQueryParam("autotheory", DelimitedArrayParam);
  // const [images, setImages] = useQueryParam("images", DelimitedArrayParam);
  const [interviews, setInterviews] = useQueryParam("interviews", DelimitedArrayParam);
  // const [suggestions, setSuggestions] = useQueryParam("suggestions", DelimitedArrayParam);

  // ————————————————————————————————————
  // Translations
  // ————————————————————————————————————

  const { t } = useTranslation();
  const { language, originalPath } = useI18next();

  const contextLanguage = language.charAt(0).toUpperCase() + language.slice(1);

  // ————————————————————————————————————
  // WP data
  // ————————————————————————————————————

  const allConcepts = data.allWpConceito.nodes;
  // console.log("allConcepts: ", allConcepts);

  const allSefTheory = data.allWpAutoteoria.nodes;
  // console.log('allSefTheory: ', allSefTheory);

  const allImages = data.allWpImagem.nodes;
  // console.log("allImages: ", allImages);

  const allInterviews = data.allWpEntrevista.nodes;
  // console.log('allInterviews: ', allInterviews);

  const allWpMembro = data.allWpMembro.nodes;
  // console.log('allWpMembro: ', allWpMembro);

  function Image({ image: { ImagemGQL } }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
      <motion.li
        key={ImagemGQL.imagem.title}
        layout
        onClick={toggleOpen}
        className={isOpen ? "images-grid__item images-grid__item__open" : "images-grid__item"}
      >
        {/* <AnimatePresence>
          <motion.div layout>
            <GatsbyImage
              draggable='false'
              key={ImagemGQL.imagem.title}
              alt={ImagemGQL.imagem.title}
              image={ImagemGQL.imagem.localFile.childImageSharp.gatsbyImageData}
            />
            <p>{ImagemGQL.imagem.title}</p>
          </motion.div>
        </AnimatePresence> */}

        <AnimatePresence>
          {/* // <Content /> */}
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="image-layout"
          >
            <motion.div
              layout
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={
                isOpen
                  ? "image-layout__header"
                  : "image-layout__header image-layout__header--vertical"
              }
              // className='image-layout__header'
            >
              <AnimatePresence>
                <GatsbyImage
                  draggable="false"
                  key={ImagemGQL.imagem.title}
                  alt={ImagemGQL.imagem.title}
                  image={ImagemGQL.imagem.localFile.childImageSharp.gatsbyImageData}
                />

                <motion.div
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="caption" style={{ marginLeft: isOpen && "20px" }}>
                    {ImagemGQL.imagem.title}
                    {parse(ImagemGQL.imagem.caption)}
                  </div>
                  <br />
                  {isOpen && (
                    <div className="caption" style={{ marginLeft: isOpen && "20px" }}>
                      Palavras-Chave <br />
                      <p> {parse(ImagemGQL[`palavrasChave${contextLanguage}`])}</p>
                    </div>
                  )}
                </motion.div>
              </AnimatePresence>
            </motion.div>

            {isOpen && (
              <AnimatePresence>
                <motion.div
                  layout
                  className="image-layout__body"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {parse(ImagemGQL[`descricao${contextLanguage}`])}
                </motion.div>
              </AnimatePresence>
            )}
          </motion.div>
        </AnimatePresence>
      </motion.li>
    );
  }

  // ————————————————————————————————————
  // Search
  // ————————————————————————————————————

  const emptyQuery = "";
  const [search, setSearch] = useState({
    filteredConcepts: [],
    filteredAutotheory: [],
    filteredImages: [],
    filteredInterviews: [],
    query: emptyQuery,
  });

  const handleInputChange = (event) => {
    const query = event.target.value;

    const filteredConcepts = allConcepts.filter((concept) => {
      const name = concept.ConceitoGQL[`nome${contextLanguage}`];
      return name.toLowerCase().includes(query.toLowerCase());
    });
    const filteredAutotheory = allSefTheory.filter((self) => {
      const name = self.AutoteoriaGQL[`nome${contextLanguage}`];
      return name.toLowerCase().includes(query.toLowerCase());
    });
    const filteredImages = allImages.filter((img) => {
      const name = img.ImagemGQL.imagem.title;
      return name.toLowerCase().includes(query.toLowerCase());
    });
    const filteredInterviews = allInterviews.filter((inter) => {
      const name = inter.EntrevistaGQL.nomeDoEntrevistado;
      return name.toLowerCase().includes(query.toLowerCase());
    });
    // update search according to the latest query and results

    setSearch({
      query, // with current query string from the `Input` event
      filteredConcepts, // with filtered data from posts.filter(post => (//filteredConcepts)) above
      filteredImages,
      filteredAutotheory,
      filteredInterviews,
    });
  };

  const { filteredConcepts, filteredAutotheory, filteredInterviews, filteredImages, query } =
    search;

  // if we have a fileredData in search and a non-emptyQuery then
  // searchQuery then `hasSearchResults` is true
  // const hasConceptsResults = filteredConcepts && query !== emptyQuery;
  const hasConceptsResults =
    typeof filteredConcepts !== "undefined" && filteredConcepts.length > 0 && query !== emptyQuery;

  const hasAutotheoryResults =
    typeof filteredAutotheory !== "undefined" &&
    filteredAutotheory.length > 0 &&
    query !== emptyQuery;

  const hasImagesResults =
    typeof filteredImages !== "undefined" && filteredImages.length > 0 && query !== emptyQuery;

  const hasInterviewsResults =
    typeof filteredInterviews !== "undefined" &&
    filteredInterviews.length > 0 &&
    query !== emptyQuery;

  // if we have a search query then return filtered data instead of all posts; else return allPosts
  const F_concepts = hasConceptsResults ? filteredConcepts : allConcepts;
  const F_autotheory = hasAutotheoryResults ? filteredAutotheory : allSefTheory;
  const F_images = hasImagesResults ? filteredImages : allImages;
  const F_interviews = hasInterviewsResults ? filteredInterviews : allInterviews;

  // const [vruuuumm, setvruuuumm] = useState(false);
  // console.log("vruuuumm: ", vruuuumm);

  // useEffect(() => {
  //   setvruuuumm(hasConceptsResults);
  // }, [hasConceptsResults]);

  console.log(
    "Hello Sherlock. 🕵️‍♂️ \nCongratulations, you found our secret badge!🎖 \nNow that you became an inspector,\nyou can find more at: www.divisa.pt 🍿\nWe hope you enjoy your quest. Bye! 👋"
  );

  const [expanded, setExpanded] = useState(false);
  const NavLink = ({ name }) => {
    return (
      <li>
        {name}
        <span className="brackets">
          [<span className="arrow">↗</span>]
        </span>
      </li>
    );
  };

  return (
    <>
      <header className="navbar">
        <div className="accordion main-container">
          <motion.header initial={false}>
            <h1>
              <span onMouseDown={() => setExpanded(!expanded)} role="button" tabIndex={0}>
                <span>condomínio</span>
                <span className="brackets">[{expanded ? "-" : "+"}]</span>
              </span>
            </h1>
            <div className="search-wrapper">
              <h2>{t("site-subtitle")}</h2>
              <input
                className="search"
                type="text"
                aria-label="Search"
                placeholder={t("search")}
                onChange={handleInputChange}
              />
            </div>
          </motion.header>

          <AnimatePresence initial={false}>
            {expanded && (
              <motion.section
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <motion.nav transition={{ duration: 0.8 }} className="navigation">
                  <ul className="navigation__links-wrapper">
                    <RSLink
                      to="presentation"
                      smooth={true}
                      duration={800}
                      spy={true}
                      offset={-300}
                      className="navigation__links-wrapper__link"
                    >
                      <NavLink name={t("presentation.title")} />
                    </RSLink>
                    <RSLink
                      to="concepts"
                      smooth={true}
                      duration={800}
                      spy={true}
                      offset={-300}
                      className="navigation__links-wrapper__link"
                    >
                      <NavLink name={t("concepts")} />
                    </RSLink>
                    <RSLink
                      to="autotheory"
                      smooth={true}
                      duration={800}
                      spy={true}
                      offset={-300}
                      className="navigation__links-wrapper__link"
                    >
                      <NavLink name={t("autotheory")} />
                    </RSLink>

                    <RSLink
                      to="images"
                      smooth={true}
                      duration={800}
                      spy={true}
                      offset={-300}
                      className="navigation__links-wrapper__link"
                    >
                      <NavLink name={t("images")} />
                    </RSLink>

                    <RSLink
                      to="interviews"
                      smooth={true}
                      duration={800}
                      spy={true}
                      offset={-300}
                      className="navigation__links-wrapper__link"
                    >
                      <NavLink name={t("interviews")} />
                    </RSLink>

                    <RSLink
                      to="suggestions"
                      smooth={true}
                      duration={800}
                      spy={true}
                      offset={-300}
                      className="navigation__links-wrapper__link"
                    >
                      <NavLink name={t("suggestions")} />
                    </RSLink>

                    <RSLink
                      to="team"
                      smooth={true}
                      duration={800}
                      spy={true}
                      offset={-300}
                      className="navigation__links-wrapper__link"
                    >
                      <NavLink name={t("team")} />
                    </RSLink>

                    <RSLink
                      to="contacts"
                      smooth={true}
                      duration={800}
                      spy={true}
                      offset={-300}
                      className="navigation__links-wrapper__link"
                    >
                      <NavLink name={t("contacts")} />
                    </RSLink>

                    <RSLink
                      to="partnerships"
                      smooth={true}
                      duration={800}
                      spy={true}
                      offset={-300}
                      className="navigation__links-wrapper__link"
                    >
                      <NavLink name={t("partnerships and support")} />
                    </RSLink>

                    <li className="navigation__links-wrapper__link">
                      {language === "pt" ? (
                        <Link to={originalPath} language="en">
                          en
                          <span className="brackets">
                            [<span className="arrow">↗</span>]
                          </span>
                        </Link>
                      ) : (
                        <Link to={originalPath} language="pt">
                          pt
                          <span className="brackets">
                            [<span className="arrow">↗</span>]
                          </span>
                        </Link>
                      )}
                    </li>
                  </ul>
                </motion.nav>
              </motion.section>
            )}
          </AnimatePresence>
        </div>
      </header>
      <main className="main-container">
        <Seo />

        <div className="content-container">
          <section name="presentation" style={{ marginBottom: "50px" }}>
            <Accordion
              opened={true}
              header={t("presentation.title")}
              body={
                <p className="max-w" style={{ margin: "0" }}>
                  {t("presentation.description")}
                </p>
              }
            />
          </section>

          <section name="concepts">
            <Accordion
              hasResults={hasConceptsResults}
              opened={concepts?.length > 0 || concepts === null}
              header={t("concepts")}
              body={
                <>
                  {F_concepts.map((concept) => {
                    const open =
                      concepts?.includes(concept.ConceitoGQL.nomeEn) ||
                      concepts?.includes(concept.ConceitoGQL.nomePt);

                    const toggleConcepts = (w) => {
                      if (concepts !== "undefined") {
                        if (!open) {
                          setConcepts([...(concepts || []), w]);
                        } else {
                          if (concepts?.length === 1) {
                            // console.log(">000000: ", concepts);
                            setConcepts(null);
                          } else {
                            setConcepts(concepts.filter((e) => e !== w));
                            // console.log("nulllllllllll");
                          }
                        }
                      }
                    };

                    return (
                      <Accordion
                        saveURL={true}
                        concert={true}
                        toggleConcepts={toggleConcepts}
                        opened={open}
                        ident={true}
                        key={concept.id}
                        header={concept.ConceitoGQL[`nome${contextLanguage}`]}
                        maxW={true}
                        body={
                          <>
                            <div className="author">
                              {concept.ConceitoGQL.autor.map((author, i) => (
                                <Accordion
                                  author={true}
                                  key={i}
                                  header={author.AutorGQL.name}
                                  body={parse(author.AutorGQL[`bio${contextLanguage}`])}
                                />
                              ))}
                            </div>

                            <div className="content">
                              {concept.ConceitoGQL[`conteudo${contextLanguage}`].map(
                                (content, i) => {
                                  // Object.keys(content)[0] === 'image';
                                  if (Object.keys(content)[0] === "text") {
                                    return <div key={i}>{parse(content.text)}</div>;
                                  } else {
                                    return (
                                      <div className="body-image" key={i}>
                                        <GatsbyImage
                                          draggable="false"
                                          alt={content.image.title}
                                          image={
                                            content.image.localFile.childImageSharp.gatsbyImageData
                                          }
                                        />
                                        <div className="caption">
                                          {content.image.title}
                                          {parse(content.image.caption)}
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>

                            <div className="excerpts">
                              <h1 className="max-w max-w--auto" style={{ marginBottom: "10px" }}>
                                {t("excerpts")}
                              </h1>
                              {concept.ConceitoGQL[`excertos${contextLanguage}`].map((excerpt) =>
                                parse(excerpt.excerpt)
                              )}
                            </div>

                            <div className="bibliography">
                              <h1 className="max-w max-w--auto" style={{ marginBottom: "10px" }}>
                                {t("bibliography")}
                              </h1>
                              {parse(concept.ConceitoGQL[`bibliografia${contextLanguage}`])}
                            </div>
                          </>
                        }
                      />
                    );
                  })}
                </>
              }
            />
          </section>

          <section name="autotheory">
            <Accordion
              hasResults={hasAutotheoryResults}
              opened={autotheory?.length > 0 || autotheory === null}
              header={t("autotheory")}
              body={
                <div>
                  {F_autotheory.map((selfTheory) => {
                    const open =
                      autotheory?.includes(selfTheory.AutoteoriaGQL.nomeEn) ||
                      autotheory?.includes(selfTheory.AutoteoriaGQL.nomePt);

                    const toggleAutotheory = (w) => {
                      if (autotheory !== "undefined") {
                        if (!open) {
                          setAutotheory([...(autotheory || []), w]);
                        } else {
                          if (autotheory?.length === 1) {
                            // console.log(">000000: ", autotheory);
                            setAutotheory(null);
                          } else {
                            setAutotheory(autotheory.filter((e) => e !== w));
                            // console.log("nulllllllllll");
                          }
                        }
                      }
                    };

                    return (
                      <Accordion
                        saveURL={true}
                        autotheory={true}
                        toggleAutotheory={toggleAutotheory}
                        opened={open}
                        ident={true}
                        key={selfTheory.id}
                        header={selfTheory.AutoteoriaGQL[`nome${contextLanguage}`]}
                        maxW={true}
                        body={
                          <>
                            <div className="author">
                              {selfTheory.AutoteoriaGQL.autor.map((author, i) => (
                                <Accordion
                                  author={true}
                                  key={i}
                                  header={author.AutorGQL.name}
                                  body={parse(author.AutorGQL[`bio${contextLanguage}`])}
                                />
                              ))}
                            </div>

                            <div className="content">
                              {selfTheory.AutoteoriaGQL[`conteudo${contextLanguage}`].map(
                                (content, i) => {
                                  // if (Object.keys(content)[0] === 'image')
                                  if (Object.keys(content)[0] === "text") {
                                    return <div key={i}> {parse(content.text)}</div>;
                                  } else {
                                    return (
                                      <div className="body-image" key={i}>
                                        <GatsbyImage
                                          draggable="false"
                                          alt={content.image.title}
                                          image={
                                            content.image.localFile.childImageSharp.gatsbyImageData
                                          }
                                        />
                                        <div className="caption">
                                          {content.image.title}
                                          {parse(content.image.caption)}
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>

                            <div className="excerpts">
                              <h1 className="max-w max-w--auto" style={{ marginBottom: "10px" }}>
                                {t("excerpts")}
                              </h1>
                              {selfTheory.AutoteoriaGQL[`excertos${contextLanguage}`].map(
                                (excerpt, i) => (
                                  <div key={i}> {parse(excerpt.excerpt)}</div>
                                )
                              )}
                            </div>

                            <div className="bibliography">
                              <h1 className="max-w max-w--auto" style={{ marginBottom: "10px" }}>
                                {t("bibliography")}
                              </h1>
                              {parse(selfTheory.AutoteoriaGQL[`bibliografia${contextLanguage}`])}
                            </div>
                          </>
                        }
                      />
                    );
                  })}
                </div>
              }
            />
          </section>

          <section name="images">
            <Accordion
              hasResults={hasImagesResults}
              header={t("images")}
              body={
                <AnimateSharedLayout>
                  <motion.ul layout className="images-grid">
                    {F_images.map((image) => (
                      <Image key={image.id} image={image} />
                    ))}
                  </motion.ul>
                </AnimateSharedLayout>
              }
            />
          </section>

          <section name="interviews">
            <Accordion
              hasResults={hasInterviewsResults}
              opened={interviews?.length > 0 || interviews === null}
              header={t("interviews")}
              body={
                <>
                  {F_interviews.map((interview) => {
                    const open = interviews?.includes(interview.EntrevistaGQL.nomeDoEntrevistado);

                    const toggleInterviews = (w) => {
                      if (interviews !== "undefined") {
                        if (!open) {
                          setInterviews([...(interviews || []), w]);
                        } else {
                          if (interviews?.length === 1) {
                            setInterviews(null);
                          } else {
                            setInterviews(interviews.filter((e) => e !== w));
                            console.log(">000000: ", interviews);
                            // console.log("nulllllllllll");
                          }
                        }
                      }
                    };

                    return (
                      <Accordion
                        saveURL={true}
                        interview={true}
                        toggleInterviews={toggleInterviews}
                        opened={open}
                        ident={true}
                        key={interview.id}
                        header={interview.EntrevistaGQL.nomeDoEntrevistado}
                        maxW={true}
                        body={
                          <>
                            <div className="content">
                              {parse(
                                interview.EntrevistaGQL[`descricaoDoEntrevistado${contextLanguage}`]
                              )}
                              <br />

                              {interview.EntrevistaGQL[`conteudo${contextLanguage}`].map(
                                (content, i) => {
                                  // if (Object.keys(content)[0] === 'resposta')
                                  if (Object.keys(content)[0] === "pergunta") {
                                    return (
                                      <h1 className="max-w max-w--auto" key={i}>
                                        {parse(content.pergunta)}
                                      </h1>
                                    );
                                  } else {
                                    return <p key={i}>{parse(content.resposta)}</p>;
                                  }
                                }
                              )}
                            </div>

                            <div className="datasheet">
                              <h1 className="max-w max-w--auto">{t("datasheet")}</h1>
                              <div className="data-sheet">
                                {parse(interview.EntrevistaGQL[`fichaTecnica${contextLanguage}`])}
                              </div>
                            </div>
                          </>
                        }
                      />
                    );
                  })}
                </>
              }
            />
          </section>

          <section name="suggestions" style={{ margin: "50px 0" }}>
            <Accordion
              header={t("suggestions")}
              body={
                <>
                  <Accordion
                    ident={true}
                    maxW={true}
                    header={t("comments")}
                    body={
                      <>
                        <p style={{ marginTop: "30px" }}>{t("comments-description")}</p>

                        <br />

                        <div className="max-w max-w--auto">
                          <NetlifyForm name="comments" action="/" honeypotName="bot-field">
                            {({ handleChange, success, error }) => (
                              <>
                                <Honeypot />

                                <div>
                                  <label htmlFor="name">
                                    <h2>{t("name")}:</h2>
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <br />
                                <div>
                                  <label htmlFor="email">
                                    <h2>email:</h2>
                                  </label>
                                  <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <br />
                                <div>
                                  <label htmlFor="message">
                                    <h2>{t("message")}:</h2>
                                  </label>
                                  <textarea
                                    type="text"
                                    name="message"
                                    id="message"
                                    rows="10"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div>
                                  <button type="submit" className="submit">
                                    <h1>
                                      {t("submit")}[<span className="arrow">↗</span>]
                                    </h1>
                                  </button>
                                </div>
                                {success && <p style={{ color: "green" }}> {t("success-form")} </p>}
                                {error && <p style={{ color: "red" }}>{t("error-form")}</p>}
                              </>
                            )}
                          </NetlifyForm>
                        </div>
                      </>
                    }
                  />
                  <Accordion
                    ident={true}
                    maxW={true}
                    header={t("entry proposals")}
                    body={
                      <>
                        <p style={{ marginTop: "30px", marginBottom: "5px" }}>
                          {t("entry proposals-description")}
                        </p>

                        <div className="max-w max-w--auto">
                          <NetlifyForm name="proposals" honeypotName="bot-field">
                            {({ handleChange, success, error }) => (
                              <>
                                <Honeypot />

                                <div>
                                  <label htmlFor="series">
                                    <h2>{t("series")}</h2>
                                  </label>
                                  <br />
                                  <input
                                    type="text"
                                    name="series"
                                    id="series"
                                    onChange={handleChange}
                                    required
                                    // style={{ marginTop: "10px" }}
                                  />
                                </div>
                                <br />
                                <br />
                                <div>
                                  <label htmlFor="suggestion">
                                    <h2>{t("suggestion")}:</h2>
                                  </label>{" "}
                                  <br />
                                  <textarea
                                    type="text"
                                    name="suggestion"
                                    id="suggestion"
                                    rows="10"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <br />
                                <div>
                                  <button type="submit" className="submit">
                                    <h1>
                                      {t("submit")}[<span className="arrow">↗</span>]
                                    </h1>
                                  </button>
                                </div>
                                {success && <p style={{ color: "green" }}> {t("success-form")} </p>}
                                {error && <p style={{ color: "red" }}>{t("error-form")}</p>}
                              </>
                            )}
                          </NetlifyForm>
                        </div>
                      </>
                    }
                  />
                </>
              }
            />
          </section>

          <section name="team">
            <Accordion
              header={t("team")}
              body={
                <div className="author">
                  {allWpMembro.map((membro, i) => (
                    <Accordion
                      key={i}
                      // ident={true}
                      header={membro.EquipaGQL.nome}
                      body={parse(membro.EquipaGQL[`descricao${contextLanguage}`])}
                    />
                  ))}
                </div>
              }
            />
          </section>

          <section name="contacts">
            <Accordion
              header={t("contacts")}
              body={
                <div className="max-w max-w--auto" style={{ padding: "0 20px" }}>
                  <h2>
                    <a href="mailto:projecto.lexico@gmail.com">
                      <span style={{ textDecoration: "underline" }}>projecto.lexico@gmail.com</span>
                      [<span className="arrow">↗</span>]
                    </a>
                  </h2>
                </div>
              }
            />
          </section>

          <section name="partnerships">
            <Accordion
              header={t("partnerships and support")}
              body={
                <div className="max-w max-w--auto" style={{ padding: "0 20px" }}>
                  <h2>
                    <a
                      href="https://www.uc.pt/iii/ceis20"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span style={{ textDecoration: "underline" }}>
                        Centro de Estudos Interdisciplinares do Século XX (CEIS20)
                      </span>
                      [<span className="arrow">↗</span>]
                    </a>
                  </h2>
                  <br />
                  <h2>
                    <a href="https://www.ceteatro.pt/" target="_blank" rel="noopener noreferrer">
                      <span style={{ textDecoration: "underline" }}>
                        Centro de Estudos de Teatro (CET)
                      </span>
                      [<span className="arrow">↗</span>]
                    </a>
                  </h2>
                  <br />
                  <h2>
                    <a href="http://www.inetmd.pt/" target="_blank" rel="noopener noreferrer">
                      <span style={{ textDecoration: "underline" }}>
                        Instituto de Etnomusicologia – Centro de Estudos em Música e Dança (INET-Md)
                      </span>
                      [<span className="arrow">↗</span>]
                    </a>
                  </h2>
                  <br />
                  <h2>
                    <a
                      href="https://www.icnova.fcsh.unl.pt/en/homepage-2/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span style={{ textDecoration: "underline" }}>
                        Instituto de Comunicação da NOVA (ICNOVA)
                      </span>
                      [<span className="arrow">↗</span>]
                    </a>
                  </h2>
                  <br />

                  <h2>
                    <a href="https://www.fct.pt/" target="_blank" rel="noopener noreferrer">
                      <span style={{ textDecoration: "underline" }}>
                        Fundação para a Ciência e a Tecnologia (FCT)
                      </span>
                      [<span className="arrow">↗</span>]
                    </a>
                  </h2>
                </div>
              }
            />
          </section>
        </div>

        <div hidden>
          <NetlifyForm name="comments" action="/" honeypotName="bot-field">
            {({ handleChange, success, error }) => (
              <>
                <Honeypot />

                <div>
                  <label htmlFor="name">
                    <h2>{t("name")}:</h2>
                  </label>
                  <input type="text" name="name" id="name" onChange={handleChange} required />
                </div>
                <br />
                <div>
                  <label htmlFor="email">
                    <h2>email:</h2>
                  </label>
                  <input type="email" name="email" id="email" onChange={handleChange} required />
                </div>
                <br />
                <div>
                  <label htmlFor="message">
                    <h2>{t("message")}:</h2>
                  </label>
                  <textarea
                    type="text"
                    name="message"
                    id="message"
                    rows="10"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <button type="submit" className="submit">
                    <h1>
                      {t("submit")}[<span className="arrow">↗</span>]
                    </h1>
                  </button>
                </div>
                {success && <p style={{ color: "green" }}> {t("success-form")} </p>}
                {error && <p style={{ color: "red" }}>{t("error-form")}</p>}
              </>
            )}
          </NetlifyForm>
          <NetlifyForm name="proposals" honeypotName="bot-field">
            {({ handleChange, success, error }) => (
              <>
                <Honeypot />

                <div>
                  <label htmlFor="series">
                    <h2>{t("series")}</h2>
                  </label>
                  <br />
                  <input
                    type="text"
                    name="series"
                    id="series"
                    onChange={handleChange}
                    required
                    // style={{ marginTop: "10px" }}
                  />
                </div>
                <br />
                <br />
                <div>
                  <label htmlFor="suggestion">
                    <h2>{t("suggestion")}:</h2>
                  </label>{" "}
                  <br />
                  <textarea
                    type="text"
                    name="suggestion"
                    id="suggestion"
                    rows="10"
                    onChange={handleChange}
                    required
                  />
                </div>
                <br />
                <div>
                  <button type="submit" className="submit">
                    <h1>
                      {t("submit")}[<span className="arrow">↗</span>]
                    </h1>
                  </button>
                </div>
                {success && <p style={{ color: "green" }}> {t("success-form")} </p>}
                {error && <p style={{ color: "red" }}>{t("error-form")}</p>}
              </>
            )}
          </NetlifyForm>
        </div>
      </main>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allWpConceito(sort: { fields: ConceitoGQL___nomePt, order: ASC }) {
      nodes {
        id
        ConceitoGQL {
          nomeEn
          nomePt
          autor {
            ... on WpAutor {
              AutorGQL {
                bioEn
                bioPt
                name
              }
            }
          }
          conteudoPt {
            ... on WpConceito_Conceitogql_ConteudoPt_Texto {
              text
            }
            ... on WpConceito_Conceitogql_ConteudoPt_Imagem {
              image {
                title
                caption
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          conteudoEn {
            ... on WpConceito_Conceitogql_ConteudoEn_Texto {
              text
            }
            ... on WpConceito_Conceitogql_ConteudoEn_Imagem {
              image {
                title
                caption
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          excertosEn {
            ... on WpConceito_Conceitogql_ExcertosEn_Excerto {
              excerpt
            }
          }
          excertosPt {
            ... on WpConceito_Conceitogql_ExcertosPt_Excerto {
              excerpt
            }
          }
          bibliografiaEn
          bibliografiaPt
        }
      }
    }

    allWpAutoteoria(sort: { fields: AutoteoriaGQL___nomePt, order: ASC }) {
      nodes {
        id
        AutoteoriaGQL {
          nomeEn
          nomePt
          autor {
            ... on WpAutor {
              id
              AutorGQL {
                bioEn
                bioPt
                name
              }
            }
          }
          conteudoPt {
            ... on WpAutoteoria_Autoteoriagql_ConteudoPt_Texto {
              text
            }
            ... on WpAutoteoria_Autoteoriagql_ConteudoPt_Imagem {
              image {
                title
                caption
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          conteudoEn {
            ... on WpAutoteoria_Autoteoriagql_ConteudoEn_Texto {
              text
            }
            ... on WpAutoteoria_Autoteoriagql_ConteudoEn_Imagem {
              image {
                title
                caption
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          excertosEn {
            ... on WpAutoteoria_Autoteoriagql_ExcertosEn_Excerto {
              excerpt
            }
          }
          excertosPt {
            ... on WpAutoteoria_Autoteoriagql_ExcertosPt_Excerto {
              excerpt
            }
          }
          bibliografiaEn
          bibliografiaPt
        }
      }
    }

    allWpImagem(sort: { fields: ImagemGQL___imagem___title, order: ASC }) {
      nodes {
        id
        ImagemGQL {
          palavrasChavePt
          palavrasChaveEn
          imagem {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            title
            caption
          }
          descricaoEn
          descricaoPt
        }
      }
    }

    allWpEntrevista(sort: { fields: EntrevistaGQL___nomeDoEntrevistado, order: ASC }) {
      nodes {
        id
        EntrevistaGQL {
          nomeDoEntrevistado
          conteudoEn {
            ... on WpEntrevista_Entrevistagql_ConteudoEn_Pergunta {
              pergunta
            }
            ... on WpEntrevista_Entrevistagql_ConteudoEn_Resposta {
              resposta
            }
          }
          conteudoPt {
            ... on WpEntrevista_Entrevistagql_ConteudoPt_Pergunta {
              pergunta
            }
            ... on WpEntrevista_Entrevistagql_ConteudoPt_Resposta {
              resposta
            }
          }
          descricaoDoEntrevistadoEn
          descricaoDoEntrevistadoPt
          fichaTecnicaEn
          fichaTecnicaPt
        }
      }
    }

    allWpMembro(sort: { fields: EquipaGQL___nome, order: ASC }) {
      nodes {
        EquipaGQL {
          nome
          descricaoPt
          descricaoEn
        }
      }
    }
  }
`;
